<template>
    <Layout>
        <b-card id="form">
            <div class="card-body">

                <b-row>

                    <BCol v-if="user.photo && !user.photo_url"  class="text-center mb-4">
                        <Cropper
                            :img="user.photo"
                            :proportion="1"
                            @set-img="setImgPerfil"
                            @reset-logo="resetPerfil"/>
                    </BCol>

                    <b-col v-else class="text-center mentor-image-container mb-4">
                        <div class="profile-user d-inline-block " style="position: relative">
                            <img v-if="user.photo_url" :src="user.photo_url" alt="Imagem_mentor"
                                 class="rounded-circle mentor-image" height="150">
                            <img v-else-if="!user.photo" src="@/assets/images/user.jpg" alt="Imagem_mentor"
                                 class="rounded-circle mentor-image" height="150">


                            <div class="avatar-xs p-0 rounded-circle profile-photo-edit"
                                 style="position: absolute;bottom: 10px;right: 8px;">
                                <input id="profile-img-file-input" style="display: none" type="file"
                                       class="profile-img-file-input" accept=".jpg, .jpeg, .png, .webp" @change="handlePerfil"/>
                                <label for="profile-img-file-input" class="profile-photo-edit avatar-xs">
                                    <span class="avatar-title rounded-circle bg-light text-body">
                                        <i class="fa fa-camera pointer"></i>
                                    </span>
                                </label>
                            </div>

                        </div>
                    </b-col>

                </b-row>

                <b-row>
                    <div class="col-md-4 mb-3">
                        <label for="name" class="form-label">
                            Nome Completo
                            <span class="text-danger">*</span>
                        </label>
                        <b-form-input id="name" type="text" name="name" v-model="user.name" required
                                      placeholder="Nome Completo"/>
                    </div>

                    <div class="col-md-4 mb-3">
                        <label for="name" class="form-label">E-mail <span class="text-danger">*</span></label>
                        <b-form-input id="name" type="email" name="name" v-model="user.email" required
                                      placeholder="E-mail de acesso"/>
                    </div>

                    <div class="col-md-4 mb-3">
                        <label for="profession" class="form-label">
                            Cargo/Profissão <span class="text-danger">*</span>
                            <i v-b-tooltip.hover.right="'Este conteúdo será exibido nas landing pages das suas mentorias.'"
                               class="fa fa-exclamation-circle my-auto ml-2"></i>
                        </label>
                        <b-form-input id="profession" type="text" name="profession" v-model="user.profession" required
                                      placeholder="Cargo/Profissão"/>
                    </div>

                    <div class="col-md-12 mb-3">
                        <label for="mini_resume" class="form-label">Mini Currículo
                            <span class="text-danger">*</span>
                            <i v-b-tooltip.hover.right="'Este conteúdo será exibido nas landing pages das suas mentorias.'"
                               class="fa fa-exclamation-circle my-auto ml-2"></i>
                        </label>
                        <b-form-textarea id="mini_resume" type="text" name="mini_resume" v-model="user.mini_resume"
                                         required placeholder="Mini Currículo">
                        </b-form-textarea>
                    </div>

                    <div class="col-md-12 mb-3">
                        <label for="professional_experience" class="form-label">
                            Experiência Profissional <span class="text-danger">*</span>
                            <i v-b-tooltip.hover.right="'Este conteúdo será exibido nas landing pages das suas mentorias.'"
                               class="fa fa-exclamation-circle my-auto ml-2"></i>
                        </label>
                        <b-form-textarea id="professional_experience" type="text" name="professional_experience"
                                         v-model="user.professional_experience" required
                                         placeholder="Experiência Profissional">
                        </b-form-textarea>
                    </div>

                    <div class="col-md-12 mb-3">
                        <label for="academic_education" class="form-label">
                            Formação Acadêmica <span class="text-danger">*</span>
                            <i v-b-tooltip.hover.right="'Este conteúdo será exibido nas landing pages das suas mentorias.'"
                               class="fa fa-exclamation-circle my-auto ml-2"></i>
                        </label>
                        <b-form-textarea id="academic_education" type="text" name="academic_education"
                                         v-model="user.academic_education" required placeholder="Formação Acadêmica">
                        </b-form-textarea>
                    </div>


                    <div class="col-md-2 mb-4">
                        <label for="cpf" class="form-label">CPF <span class="text-danger">*</span></label>
                        <b-form-input id="cpf" type="text" name="cpf" v-model="user.cpf" required placeholder="CPF"
                                      v-maska="'###.###.###-##'"/>
                    </div>

                    <div class="col-md-2 mb-4">
                        <label for="phone" class="form-label">Telefone <span class="text-danger">*</span></label>
                        <b-form-input id="phone" type="text" name="phone" v-model="user.phone" required
                                      placeholder="Telefone" v-maska="['(##) #####-####', '(##) ####-####']"/>
                    </div>

                    <div class="col-md-2 mb-4">
                        <label for="cep" class="form-label">CEP <span class="text-danger">*</span></label>
                        <b-form-input id="cep" type="text" name="cep" v-model="user.cep" required placeholder="CEP"
                                      v-maska="'##.###-###'" @keyup="setAddress"/>
                    </div>

                    <div class="col-md-1 mb-4">
                        <label for="uf" class="form-label">UF <span class="text-danger">*</span></label>
                        <b-form-input id="uf" type="text" name="uf" v-model="user.uf" required placeholder="UF"
                                      maxlenght="2"/>
                    </div>

                    <div class="col-md-2 mb-4">
                        <label for="city" class="form-label">Cidade <span class="text-danger">*</span></label>
                        <b-form-input id="city" type="text" name="city" v-model="user.city" required
                                      placeholder="Cidade" maxlenght="2"/>
                    </div>

                    <!-- <div class="col-md-3 mb-4">
                        <label for="neighborhood" class="form-label">Bairro <span class="text-danger">*</span></label>
                        <b-form-input id="neighborhood" type="text" v-model="user.neighborhood" name="neighborhood"
                                      required
                                      placeholder="Bairro"/>
                    </div> -->

                    <!-- <div class="col-md-4 mb-4">
                        <label for="street" class="form-label">Logadouro <span class="text-danger">*</span></label>
                        <b-form-input id="street" type="text" name="street" v-model="user.street" required
                                      placeholder="Logadouro"/>
                    </div> -->

                    <!-- <div class="col-md-2 mb-4">
                        <label for="number" class="form-label">Número <span class="text-danger">*</span></label>
                        <b-form-input id="number" type="text" name="number" v-model="user.number" required
                                      placeholder="Número"/>
                    </div> -->

                    <!-- <div class="col-md-3 mb-4">
                        <label for="complement" class="form-label">Complemento</label>
                        <b-form-input id="complement" type="text" v-model="user.complement" name="complement"
                                      placeholder="Complemento"/>
                    </div> -->

                    <div class="col-md-3 mb-4">
                        <label for="instagram" class="form-label">
                            Instagram
                            <i v-b-tooltip.hover.right="'Se preenchido, será disponibilizado o link do seu perfil nas landing pages das suas mentorias.'"
                               class="fa fa-exclamation-circle my-auto ml-2"></i>
                        </label>
                        <b-form-input id="instagram" type="text" v-model="user.instagram" name="instagram"
                                      placeholder="Instagram"/>
                    </div>

                </b-row>
<!--                <div class="row">-->
<!--                    <div class="col-md-6 my-auto mb-3">-->
<!--                        <label class="form-label mt-2">Banner do Mentor</label>-->
<!--                        <input type="file" id="banner_image" class="form-control mb-3" @change="handleBanner"-->
<!--                               required accept=".jpg, .jpeg, .png, .webp">-->
<!--                        <div class="alert alert-warning">-->
<!--                            Dimensões indicadas: 320 x 480-->
<!--                        </div>-->

<!--                        <div class="alert alert-danger" v-if="user.banner_alert">-->
<!--                            As dimensões da sua imagem são menores que o recomendado, o que pode resultar em-->
<!--                            distorções ou perda de qualidade ao ser exibida.-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="col-md-6 mb-3 text-end">-->
<!--                        <img v-if="user.banner_url" :src="user.banner_url" id="banner_image"-->
<!--                             class="mentor-imagem" alt="nova_banner_mentoria" width="320">-->
<!--                        <Cropper-->
<!--                            v-else-if="user.banner_image && !user.banner_url" :img="user.banner_image"-->
<!--                            :proportion="0.66"-->
<!--                            @set-img="setImgBanner"-->
<!--                            @reset-logo="resetBanner"/>-->
<!--                        <img v-else class="img-fluid" src="../../assets/images/modulo.png" id="banner_image"-->
<!--                             alt="banner_image_mentoria">-->
<!--                    </div>-->
<!--                </div>-->

                <div class="row">
                    <div class="col text-right mt-3">
                        <b-button type="button" @click="save" variant="primary" id="save">Salvar</b-button>
                    </div>
                </div>

            </div>

        </b-card>
    </Layout>
</template>

<script>

import Layout from '@/components/layout/main.vue';
import http from '@/http'
import {endLoading, startLoading} from "@/components/composables/spinners";
import {toFormData, ValidateForm} from "@/components/composables/cruds";
import Cropper from "@/components/base/cropper.vue";
import {handleImg, setImg} from "@/components/composables/img";

export default {
    components: {
        Layout, Cropper
    },

    data() {
        return {
            user: {
                name: '',
                cpf: '',
                phone: '',
                cep: '',
                uf: '',
                city: '',
                neighborhood: '',
                street: '',
                number: '',
                complement: '',
                instagram: '',
                banner_url: '',
                banner_image: '',
                banner_alert: false,
                photo_url: null,
                photo: null,
                photo_alert: false,
            },
            currentUser: this.$store.state.auth.user,
        }
    },

    methods: {

        async handleBanner(event) {

            try {
                const {url, img, alert} = await handleImg(event, 320, 480);
                this.user.banner_image = img;
                this.user.banner_url = url;
                this.user.banner_alert = alert;

            } catch (error) {
                console.error("Erro ao carregar a imagem:", error);
            }
        },
        setImgBanner(blob) {
            const {url, image} = setImg(blob);
            this.user.banner_url = url;
            this.user.banner_image = image;
        },
        resetBanner() {
            document.getElementById('banner_image').value = '';
            this.user.banner_image = null;
            this.user.banner_url = null;
            this.user.banner_alert = false;
        },

        async handlePerfil(event) {

            try {
                const {url, img, alert} = await handleImg(event, 320, 480);
                this.user.photo = img;
                this.user.photo_url = url;
                this.user.photo_alert = alert;

            } catch (error) {
                console.error("Erro ao carregar a imagem:", error);
            }
        },
        setImgPerfil(blob) {
            const {url, image} = setImg(blob);
            this.user.photo_url = url;
            this.user.photo = image;
        },
        resetPerfil() {
            document.getElementById('profile-img-file-input').value = '';
            this.user.photo = null;
            this.user.photo_url = null;
            this.user.photo_alert = false;
        },

        save() {
            startLoading('form', 'save');
            this.$store.commit('api/setSpinner', true);
            const required = {
                name: 'Nome',
                profession: 'Cargo/Profissão',
                mini_resume: 'Mini Currículo',
                professional_experience: 'Experiência Profissional',
                academic_education: 'Formação Acadêmica',
                cpf: 'CPF',
                phone: 'Telefone',
                cep: 'CEP',
                uf: 'UF',
                city: 'Cidade',
                // neighborhood: 'Bairro',
                // street: 'Logradouro',
                // number: 'Número',

            };
            if (!ValidateForm(this.$root, this.user, required)) {
                endLoading('form', 'save');
                this.$store.commit('api/setSpinner', false);
                return;
            }

            let data = toFormData(this.user);

            data.append('id', this.currentUser.id);
            data.append('_method', 'PUT');

            const url = 'mentors/' + this.currentUser.id;

            http.post(url, data)
                .then(() => {
                    this.$store.dispatch('auth/refreshUser');
                    this.$notifySuccess('Dados atualizados com sucesso!');
                    this.$router.push('/home')
                })
                .catch(e => {
                    console.error('Mentor Data: ', e.response);
                    this.$notifyError(e);
                })
                .finally(() => {
                    endLoading('form', 'save');
                    this.$store.commit('api/setSpinner', false);
                })
        },

        getData() {
            startLoading('form', 'save');
            this.$store.commit('api/setSpinner', true);
            http.get('mentors/?id=' + this.currentUser.id)
                .then(response => {
                    this.user = response.data.data[0];
                })
                .catch(e => {
                    console.error('Mentor Data: ', e.response);
                    this.$notifyError('Erro ao carregar os dados do Mentor!');
                })
                .finally(() => {
                    endLoading('form', 'save');
                    this.$store.commit('api/setSpinner', false);
                })
        },

        async setAddress() {
            var cep = this.user.cep.replace(/\D/g, '');
            var validacep = /^[0-9]{8}$/;
            if (validacep.test(cep)) {
                http.get("https://viacep.com.br/ws/" + cep + "/json/?callback=?")
                    .then(response => {
                        let json = response.data.replace('?(', '').replace(');', '');
                        json = JSON.parse(json)

                        this.user.uf = json.uf;
                        this.user.city = json.localidade;
                    })
            }
        },
    },

    mounted() {
        this.getData();
    }
}

</script>

<style scoped="true">
/* Example CSS */
.mentor-image-container {
    background: rgb(131, 58, 180);
    background: linear-gradient(90deg, rgba(131, 58, 180, 1) 0%, rgba(253, 29, 29, 1) 77%, rgba(252, 176, 69, 1) 100%);
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}


.mentor-image {
    border: white solid 3px;
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}
</style>
